.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.userDetailsRightBtns {
  justify-content: flex-end;
  margin-right: auto !important;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.userDetailsFormRowCol {
    padding: 10px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.drivingLicenceModal {
    max-width: 90% !important;
}
input[type="checkbox"] {
    background: #35b6c6;
 }
