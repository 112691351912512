.container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.img-holder{
  margin: auto;
  width: 150px;
  height: 150px;
  border: 3px black solid;
  border-radius: 5px;
  margin-top: 1rem;
}
.image{
  width: 35%;
  height: 35%;
  object-fit: cover;
}