.card-header-one{
    background-color: #333399;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
}

.head-text {
    position: relative;
 }
 .text-on-image-topup-1 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 20px;
    top: 300px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-1 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 5px;
    top: 325px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-2 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 125px;
    top: 300px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-2 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 103px;
    top: 322px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-3 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 20px;
    top: 372.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-3 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: -2px;
    top: 395px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-4 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 125px;
    top: 372.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-4 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 103px;
    top: 395px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-5 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 20px;
    top: 445.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-5 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: -2px;
    top: 469px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-6 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 125px;
    top: 445.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-6 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 103px;
    top: 469px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }


 .android-text-on-image-topup-1 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 20px;
    top: 289px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-1 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: -3px;
    top: 308px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-2 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 115px;
    top: 289px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-2 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 103px;
    top: 308px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-3 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 20px;
    top: 342.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-3 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: -2px;
    top: 361px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-4 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 115px;
    top: 342.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-4 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 105px;
    top: 361px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-5 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 20px;
    top: 396.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-5 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: -2px;
    top: 416px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-6 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 115px;
    top: 396.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-6 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 103px;
    top: 416px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }