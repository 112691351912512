* {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tableTitle {
  font-size: 26px;
  font-weight: 500;
}
.MuiButton-containedPrimary {
  background-color: #36b5c7 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import '@fortawesome/fontawesome-free/css/all';
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@200;300;400;500&display=swap');

.btnUnderLine {
  border-bottom: 3px solid #333399 !important;
}
.chartIconAvatar{
  border: 2px solid #32adbe !important;
}
button:focus {
outline: none !important;
}
.leaflet-routing-container{
  display: none !important;
}
.sidebar-collapse {
  z-index: 999 !important;
}
.vehicle_popover > .MuiPopover-paper {
  min-width: 400px;
}
.leaflet-top, .leaflet-bottom {
  z-index: 800 !important;
}

.bikeCarImage {
  height: 110px;
  width: 150px;
  margin-left: 25%;

}
.EscooterEmopedImage {
  height: 140px;
  width: 140px;
  margin-left: 25%;
}

.detailsFormLabel {
  padding: 10px;
}
.primary {
  color: #36b5c7 !important;
}

.textAlignCenter {
  text-align: center;
}

.height100 {
  height: 100%;
}

.padding10 {
  padding: 10px;
}
.padding5 {
   padding: 5px !important;
}

.cursor-not-allowed{
  cursor: not-allowed;
}
.white{
  color: #ffffff !important;
}
.spare-parts-box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  height: 600px;
  outline: 3px dotted #03989E;
}
.spare-parts-card-image{
  color: black;
  font-weight: 400;
  height: 100px;
}
.spare-parts-card-title{
  color: black;
  font-weight: 400
}
.cart{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
}
.collection .collection-item{
  display: flex;
}
.collection-item.avatar .title {
  font-size: 20px;
  font-weight: 500;
  color: #ee6e73;
  display: block;
  margin-bottom: 20px;
}
.collection .collection-item img{
  width: 150px;
  margin-right: 25px;
}
.spare-parts-card{
  width: 300px;
}
.spare-parts-img{
  height: 100px;
}
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}