.messagesDropdown {
    height:50vh;
}

.dropdown-toggle.disable-arrow::after {
    display: none;
}

.search-dropdown {
    min-width: 402px !important;
}

.dropdown-item.search-item {
    padding: 0.25rem 1.5rem 0.5rem 0.25rem;
}

.avatar {
    width: 65px !important;
    height: 65px !important;
}

.avatar-icon {
    position: relative;
    left: 41px;
    top: -26px;
}

.search-info {
    font-size: 13px;
}

.alert-list {
    max-height: 450px;
    overflow-y: auto !important;
}

.pointer {
    cursor: pointer;
}

.alert-list-show {
    display: block;
} 