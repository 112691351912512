.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  z-index: 998;
  background: rgba(0, 0, 0, 0.75);
}

.backdropPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  z-index: 400;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  z-index: 999;
  overflow: hidden;
}