@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@200;300;400;500&display=swap);
* {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tableTitle {
  font-size: 26px;
  font-weight: 500;
}
.MuiButton-containedPrimary {
  background-color: #36b5c7 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btnUnderLine {
  border-bottom: 3px solid #333399 !important;
}
.chartIconAvatar{
  border: 2px solid #32adbe !important;
}
button:focus {
outline: none !important;
}
.leaflet-routing-container{
  display: none !important;
}
.sidebar-collapse {
  z-index: 999 !important;
}
.vehicle_popover > .MuiPopover-paper {
  min-width: 400px;
}
.leaflet-top, .leaflet-bottom {
  z-index: 800 !important;
}

.bikeCarImage {
  height: 110px;
  width: 150px;
  margin-left: 25%;

}
.EscooterEmopedImage {
  height: 140px;
  width: 140px;
  margin-left: 25%;
}

.detailsFormLabel {
  padding: 10px;
}
.primary {
  color: #36b5c7 !important;
}

.textAlignCenter {
  text-align: center;
}

.height100 {
  height: 100%;
}

.padding10 {
  padding: 10px;
}
.padding5 {
   padding: 5px !important;
}

.cursor-not-allowed{
  cursor: not-allowed;
}
.white{
  color: #ffffff !important;
}
.spare-parts-box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  height: 600px;
  outline: 3px dotted #03989E;
}
.spare-parts-card-image{
  color: black;
  font-weight: 400;
  height: 100px;
}
.spare-parts-card-title{
  color: black;
  font-weight: 400
}
.cart{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
}
.collection .collection-item{
  display: flex;
}
.collection-item.avatar .title {
  font-size: 20px;
  font-weight: 500;
  color: #ee6e73;
  display: block;
  margin-bottom: 20px;
}
.collection .collection-item img{
  width: 150px;
  margin-right: 25px;
}
.spare-parts-card{
  width: 300px;
}
.spare-parts-img{
  height: 100px;
}
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}
.messagesDropdown {
    height:50vh;
}

.dropdown-toggle.disable-arrow::after {
    display: none;
}

.search-dropdown {
    min-width: 402px !important;
}

.dropdown-item.search-item {
    padding: 0.25rem 1.5rem 0.5rem 0.25rem;
}

.avatar {
    width: 65px !important;
    height: 65px !important;
}

.avatar-icon {
    position: relative;
    left: 41px;
    top: -26px;
}

.search-info {
    font-size: 13px;
}

.alert-list {
    max-height: 450px;
    overflow-y: auto !important;
}

.pointer {
    cursor: pointer;
}

.alert-list-show {
    display: block;
} 
.newIcon {
    height: 20px;
    width: 20px;
  }

.dropDownVisibility {
  z-index: 10;
}
.leaflet-container {
    width: 100%;  
    height: 100%;
 }
.fullscreen-enabled .leaflet-container {
background: #000;
}
.legend {
    text-align: left;
    line-height: 18px;
    color: #999;
  }
.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }
.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.userDetailsRightBtns {
  justify-content: flex-end;
  margin-right: auto !important;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.userDetailsFormRowCol {
    padding: 10px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.drivingLicenceModal {
    max-width: 90% !important;
}
input[type="checkbox"] {
    background: #35b6c6;
 }


.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.formLabel {
    padding-top: 20px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.formTextField {
    width: 100%;
    margin: 10px 0px 10px 0px !important;
}
.submitBtn{
    width: 20%;
}
.leaflet-container {
    width: 100%;  
    height: 50vh;
 }
.popup-box {
    position: relative;
    background: #00000050;
    width: 100%;
    height: 25vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 90%;
    margin: 0 auto;
    height: auto;
    max-height: 25vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
.Modal_backdrop__3FAPo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  z-index: 998;
  background: rgba(0, 0, 0, 0.75);
}

.Modal_backdropPopup__3ufe8 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  z-index: 400;
  background: rgba(0, 0, 0, 0.75);
}

.Modal_modal__P3_V5 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  z-index: 999;
  overflow: hidden;
}
.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.formLabel {
    padding-top: 20px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

path.leaflet-interactive.routeAnimate {
    stroke-dasharray: 1920;
    stroke-dashoffset: 1920;
    animation: dash 10s linear 0s forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.formLabel {
    padding-top: 20px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.formLabel {
    padding-top: 20px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.userDetailsFormLabel {
    padding: 10px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.userDetailsRightBtns {
  justify-content: flex-end;
  margin-right: auto !important;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.userDetailsFormRowCol {
    padding: 10px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.drivingLicenceModal {
    max-width: 90% !important;
}
input[type="checkbox"] {
    background: #35b6c6;
 }

.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.formLabel {
    padding-top: 20px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.userDetailsFormLabel {
    padding: 10px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.leaflet-container {
    width: 100%;  
    height: 50vh;
 }
.PricingStruture {
    background: #FFFFFF;
    border: 1px solid rgba(3, 152, 158, 0.48) !important;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px !important;
    margin: 40px;

}

.vehicleIcon {
    width: 163px;
    height: 163px;
}
.regionLabel {
    font-weight: 800;
    font-size: 36px;
    line-height: 22px;
    color: #03989E;
    padding-left: 40px;
    margin-bottom: 20px;
}

.pricingFeeLabelOne {
    justify-content: center;
    padding-left: 15px;
    padding-right: 24px;
    padding-top: 15px;
}
.pricingFeeLabelTwo {
    justify-content: center;
    padding-left: 15px;
    padding-right: 24px;
}
.bikeLabel {
    justify-content: center;
    padding-top: 10px;
    color: #03989E;
    font-size: 16px;

}
.pricingFee {
    color: #03989E;
    font-size: 15px;
}

.heading-wrapper {
    color: rgba(3, 152, 158, 0.48);
    display: flex;
    align-items: center;
    padding-bottom: .5rem;
}

.heading-wrapper svg {
    font-size: 27px;
}

.headingLabel {
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
    color: #03989E;
    padding: 3px 3px 3px 30px;
    margin-right: .25rem;
    margin-bottom: 0px;
}

.VehicleCard {
 
    flex: 0 0 277px;
    background: #FFFFFF;
    padding: 1rem;
    border: 1px solid rgba(3, 152, 158, 0.48) !important;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px !important;
    margin: 15px;
  }
  
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .action-buttons {
      display: flex;
      justify-content: space-between;
      padding: 10px 40px 15px 40px;
  }

  .form-field {
      padding: .75rem 2.5rem;
  }
  
  .dialog-title {
    color: #FFFFFF;
    background-color: #36b9cc;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem !important;
    justify-content: center;
    padding: 16px 24px 8px 24px !important;
  }

  .c-red {
      color: red;
  }
.str-chat {
  height: 90vh;
  font-family: 'Urbanist', sans-serif;
}
.detailsTab {
    padding: 2%;
}

.DetailsCard .tab-content {
    background: #36b5c7;
    padding-bottom: 10px;
}

.btn-row {
    justify-content: center;
}
.nav-item.nav-link.active {
    background: #36b5c7 !important;
    border-color: #dee2e6 #dee2e6 #35b6c6 !important;
}
#imgRow img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.userDetailsFormLabel {
    padding: 10px;
}
.DetailsCard {
    padding: 2% !important;
}
.userLabel {
    font-size: larger;
    font-weight: bold;
}
#paymentBind {
    height: 25px;
    width: 20px;
}

.paymentBind label {
    margin-left: 10px;
    margin-top: 5px;
}

.create-container {
   min-width: 500px
}
.input-popup-header {
  color: white;
  background-color: #36b9cc;
  text-align: center
}

.input-popup-dialog-actions {
   justify-content: space-between;
   padding: 10px 40px 15px 40px;
}
.battery-detail-avatar {
  width: 80px !important;
  height: 80px !important;
  background-color: #36b5c7 !important;
}

.battery-detail-avatar-icon {
  width: 60px !important;
  height: 60px !important;
}
.search-slt-wrapper {
    border: 2px solid #BFBDBD;
    border-radius: 10px;
    position: relative;
}

.search-slt-wrapper input {
    border: none;
    border-radius: inherit;
    padding: 32px 16px;
}

.search-slt-wrapper input:focus {
    box-shadow: none;
}

.search-slt-wrapper label {
    position: absolute;
    top: -12px;
    left: 20px;
    display: block;
    background-color: white;
    padding: 0 16px;
    font-size: 14px;
    color: black;
}

.create-container {
   min-width: 500px
}
.card-header-one{
    background-color: #333399;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
}

.head-text {
    position: relative;
 }
 .text-on-image-topup-1 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 20px;
    top: 300px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-1 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 5px;
    top: 325px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-2 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 125px;
    top: 300px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-2 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 103px;
    top: 322px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-3 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 20px;
    top: 372.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-3 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: -2px;
    top: 395px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-4 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 125px;
    top: 372.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-4 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 103px;
    top: 395px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-5 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 20px;
    top: 445.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-5 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: -2px;
    top: 469px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .text-on-image-topup-6 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 125px;
    top: 445.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .text-on-image-bonus-6 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 103px;
    top: 469px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }


 .android-text-on-image-topup-1 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 20px;
    top: 289px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-1 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: -3px;
    top: 308px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-2 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 115px;
    top: 289px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-2 {
    position: absolute;
    width: 169px;
    height: 250.75px;
    left: 103px;
    top: 308px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-3 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 20px;
    top: 342.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-3 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: -2px;
    top: 361px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-4 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 115px;
    top: 342.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-4 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 105px;
    top: 361px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-5 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 20px;
    top: 396.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-5 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: -2px;
    top: 416px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }

 .android-text-on-image-topup-6 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 115px;
    top: 396.5px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #FFFFFF;
 }

 .android-text-on-image-bonus-6 {
    position: absolute;
    width: 169px;
    height: 100.75px;
    left: 103px;
    top: 416px;

    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    /* or 100% */

    text-align: center;

    color: #000000;
 }
.leaflet-popup-content p {
  margin: 0;
}

.leaflet-popup-content{
  max-width: 500px;
  width: 100%;
  min-width: 350px;
  min-height: 20vh
}
.container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.img-holder{
  margin: auto;
  width: 150px;
  height: 150px;
  border: 3px black solid;
  border-radius: 5px;
  margin-top: 1rem;
}
.image{
  width: 35%;
  height: 35%;
  object-fit: cover;
}
