.PricingStruture {
    background: #FFFFFF;
    border: 1px solid rgba(3, 152, 158, 0.48) !important;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px !important;
    margin: 40px;

}

.vehicleIcon {
    width: 163px;
    height: 163px;
}
.regionLabel {
    font-weight: 800;
    font-size: 36px;
    line-height: 22px;
    color: #03989E;
    padding-left: 40px;
    margin-bottom: 20px;
}

.pricingFeeLabelOne {
    justify-content: center;
    padding-left: 15px;
    padding-right: 24px;
    padding-top: 15px;
}
.pricingFeeLabelTwo {
    justify-content: center;
    padding-left: 15px;
    padding-right: 24px;
}
.bikeLabel {
    justify-content: center;
    padding-top: 10px;
    color: #03989E;
    font-size: 16px;

}
.pricingFee {
    color: #03989E;
    font-size: 15px;
}

.heading-wrapper {
    color: rgba(3, 152, 158, 0.48);
    display: flex;
    align-items: center;
    padding-bottom: .5rem;
}

.heading-wrapper svg {
    font-size: 27px;
}

.headingLabel {
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
    color: #03989E;
    padding: 3px 3px 3px 30px;
    margin-right: .25rem;
    margin-bottom: 0px;
}

.VehicleCard {
 
    flex: 0 0 277px;
    background: #FFFFFF;
    padding: 1rem;
    border: 1px solid rgba(3, 152, 158, 0.48) !important;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px !important;
    margin: 15px;
  }
  
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .action-buttons {
      display: flex;
      justify-content: space-between;
      padding: 10px 40px 15px 40px;
  }

  .form-field {
      padding: .75rem 2.5rem;
  }
  
  .dialog-title {
    color: #FFFFFF;
    background-color: #36b9cc;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem !important;
    justify-content: center;
    padding: 16px 24px 8px 24px !important;
  }

  .c-red {
      color: red;
  }