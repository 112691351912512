.leaflet-container {
    width: 100%;  
    height: 100%;
 }
.fullscreen-enabled .leaflet-container {
background: #000;
}
.legend {
    text-align: left;
    line-height: 18px;
    color: #999;
  }
.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }