.search-slt-wrapper {
    border: 2px solid #BFBDBD;
    border-radius: 10px;
    position: relative;
}

.search-slt-wrapper input {
    border: none;
    border-radius: inherit;
    padding: 32px 16px;
}

.search-slt-wrapper input:focus {
    box-shadow: none;
}

.search-slt-wrapper label {
    position: absolute;
    top: -12px;
    left: 20px;
    display: block;
    background-color: white;
    padding: 0 16px;
    font-size: 14px;
    color: black;
}
