path.leaflet-interactive.routeAnimate {
    stroke-dasharray: 1920;
    stroke-dashoffset: 1920;
    animation: dash 10s linear 0s forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}